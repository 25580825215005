import { useState, useEffect, useLayoutEffect } from "react"
import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/three"
import BgPlane from "./bgPlane"

function VideoPlayer({
  url,
  rotation = [0, 0, 0],
  position = [0, 0, 0],
  scale = 1,
  border = true,
  show = false,
  onClick,
  startAnim = false,
  startAnimInit = false,
  hideAnim = false,
  hideAnimInit = false,
}) {
  const [video] = useState(() => {
    const vid = document.createElement("video")
    vid.src = url
    vid.crossOrigin = "Anonymous"
    vid.loop = true
    vid.muted = true
    vid.play()
    return vid
  })

  const [enlarged, setEnlarged] = useState(0)
  const [scaleState, setScaleState] = useState(0)

  const { spring } = useSpring({
    spring: scaleState,
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 },
  })
  const scaleAnim = startAnim ? spring.to([0, 1], [0, scale]) : scale

  useLayoutEffect(() => {
    if (startAnim) {
      setScaleState(startAnimInit ? 1 : 0)
    }
  }, [startAnim, startAnimInit])

  useLayoutEffect(() => {
    if (hideAnimInit) setScaleState(0)
  }, [hideAnimInit])

  console.log(
    "videoPlayer: startAnimInit:",
    startAnimInit,
    "hideAnimInit:",
    hideAnimInit,
    "scaleState:",
    scaleState,
    "scaleAnim:",
    scaleAnim.get ? scaleAnim.get() : ""
  )

  return show ? (
    <a.group position={position} scale={scaleAnim} rotation={rotation}>
      {border ? (
        <BgPlane
          key="videoPlayerBg"
          width={3.09}
          height={1.76}
          position={[0, 0, -0.02]}
        />
      ) : null}
      <mesh
        key="video"
        position={[0, 0, 0]}
        onClick={() => setEnlarged(Number(!enlarged))}
        scale={1}
      >
        <planeGeometry args={[3, 1.69]} />
        <meshStandardMaterial>
          <videoTexture attach="map" args={[video]} />
        </meshStandardMaterial>
      </mesh>
    </a.group>
  ) : null
}

export default VideoPlayer
