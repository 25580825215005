import { Text } from "@react-three/drei"
import { useState, useEffect } from "react"
import GradientText from "../components/gradientText"

function TextInput({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  label = "",
  labelColor = "white",
  onChange,
  focus = false,
  value = "",
}) {
  return (
    <group position={position} rotation={rotation}>
      <Text
        position={[-0, 0, 0]}
        color={labelColor}
        anchorX="right"
        anchorY="middle"
        fontSize={0.3}
      >
        {label}
      </Text>
      <GradientText position={[0.08, 0, 0]} anchorX="left">
        {value}
      </GradientText>
    </group>
  )
}

export default TextInput
