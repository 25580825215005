import { useState, useEffect, useRef } from "react"
import Html from "@react-three/drei"
import { useSpring, config, animated } from "@react-spring/three"
import BgPlane from "../components/bgPlane"
import TextInput from "./textInput"
import Button from "./button"

function Booking({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  onBooking,
  scale = 1,
  active = false,
  camArrived,
  onSceneSet,
  customer = { firstName: "", mail: "" },
}) {
  const ref = useRef()
  const inputRef = useRef("")
  const initRef = useRef(true)
  const [init, setInit] = useState(true)
  const [sceneHiding, setSceneHiding] = useState(false)
  const fieldFocusedRef = useRef("none")
  const [customerData, setCustomerData] = useState({
    firstName: "",
    mail: "",
  })
  const [firstName, setFirstName] = useState(customer.firstName)
  const [mail, setMail] = useState(customer.mail)
  const [update, setUpdate] = useState(false)
  const [fieldFocused, setFieldFocused] = useState("none")

  const [springFirstNameVal, springFirstName] = useSpring(() => ({
    position: [0, 0, 0],
    scale: 1,
  }))

  const [springMailVal, springMail] = useSpring(() => ({
    position: [0, 0, 0],
    scale: 1,
  }))

  const [springBtnContinueVal, springBtnContinue] = useSpring(() => ({
    position: [0, -20, -20],
    scale: 0,
  }))

  const handleInput = (e) => {
    console.log("key", e, fieldFocused)
    if (
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Enter" &&
      e.keyCode !== 8
    ) {
      inputRef.current += e.key
      if (fieldFocusedRef.current === "firstName")
        setFirstName(inputRef.current)

      if (fieldFocusedRef.current === "mail") setMail(inputRef.current)
      /*
        setCustomerData({
        ...customerData,
        [fieldFocusedRef.current]: (" " + inputRef.current).slice(1),
      })

      setUpdate(!update)
      */
    }
  }

  const deleteCharacter = () => {
    console.log("del")
    inputRef.current = inputRef.current.substr(0, inputRef.current.length - 1)
    if (fieldFocusedRef.current === "firstName") setFirstName(inputRef.current)
    if (fieldFocusedRef.current === "mail") setMail(inputRef.current)
    /*
    setCustomerData({
      ...customerData,
      [fieldFocusedRef.current]: (" " + inputRef.current).slice(1),
    })

    setUpdate(!update)
    */
  }

  const handleKeydown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete" || e.keyCode === 8) {
      deleteCharacter()
      setUpdate(!update)
    }
    if (e.key === "Enter" || e.key === "enter" || e.key === "Tab") {
      fieldFocusedRef.current =
        fieldFocusedRef.current === "none"
          ? "firstName"
          : fieldFocusedRef.current === "firstName"
          ? "mail"
          : "none"
      if (fieldFocusedRef.current === "firstName") inputRef.current = firstName
      if (fieldFocusedRef.current === "mail") inputRef.current = mail
      setFieldFocused(fieldFocusedRef.current)
      setUpdate(!update)
    }
  }

  const handleSceneSwitch = (newScene) => {
    if (onSceneSet) {
      fieldFocusedRef.current = "none"
      setFieldFocused(fieldFocusedRef.current)
      onSceneSet(newScene)
    }
  }

  useEffect(() => {
    document.addEventListener("keypress", handleInput)
    document.addEventListener("keydown", handleKeydown)
    setTimeout(() => {
      initRef.current = false
      setInit(initRef.current)
    }, 2900)
  }, [])

  useEffect(
    () => () => {
      document.removeEventListener("keypress", handleInput)
      document.removeEventListener("keydown", handleKeydown)
    },
    []
  )

  useEffect(() => {
    console.log("book: cam arrived", camArrived)
    if (camArrived) {
      setTimeout(() => {
        fieldFocusedRef.current = "firstName"
        setFieldFocused(fieldFocusedRef.current)
      }, 250)
    } else {
      setFieldFocused("none")
    }
  }, [camArrived])

  console.log("book", fieldFocused, customerData, inputRef.current)

  useEffect(() => {
    inputRef.current = ""
    switch (fieldFocused) {
      case "none":
        springFirstName.start({
          position: [0, 0.3, 0],
          scale: 1,
        })
        springMail.start({
          position: [0, -0.3, 0],
          scale: 1,
        })
        springBtnContinue.start({
          position: [0, -2, 0],
          scale: firstName.length > 1 && mail.length > 1 ? 2 : 0,
        })
        break
      case "firstName":
        springFirstName.start({
          position: [0, 0, 0],
          scale: 2,
        })
        springMail.start({
          position: [0, -1, 0],
          scale: 1,
        })
        springBtnContinue.start({
          position: [0, -2, 0],
          scale: firstName.length > 1 && mail.length > 1 ? 1 : 0,
        })
        break
      case "mail":
        springFirstName.start({
          position: [0, 1, 0],
          scale: 1,
        })
        springMail.start({
          position: [0, 0, 0],
          scale: 2,
        })
        springBtnContinue.start({
          position: [0, -2, 0],
          scale: firstName.length > 1 && mail.length > 1 ? 1 : 0,
        })
        break
      default:
        springFirstName.start({
          position: [0, 0.3, 0],
          scale: 1,
        })
        springMail.start({
          position: [0, -0.3, 0],
          scale: 1,
        })
        if (firstName.length > 0 && mail.length > 0) {
          springBtnContinue.start({
            position: [0, -2, 0],
            scale: firstName.length > 0 && mail.length > 0 ? 1 : 0,
          })
        }
    }
  }, [fieldFocused])

  useEffect(() => {
    onBooking({
      firstName,
      mail,
    })
  }, [firstName, mail])

  /*
  useFrame(() => {
    if (null !== ref.current) {
      ref.current.lookAt(camera.position)
    }
  })
  */
  return (
    <group
      ref={ref}
      position={position}
      update={update}
      rotation={rotation}
      scale={scale}
      onBooking={onBooking}
    >
      <BgPlane
        key="bgBooking"
        width={10}
        height={7}
        position={[0, -0, -0.1]}
        background={false}
        startAnim
        startAnimInit={!init}
        hideAnim
        hideAnimInit={!init && !active}
      />

      <animated.group
        position={springFirstNameVal.position}
        scale={springFirstNameVal.scale}
      >
        <TextInput label="Vorname:" value={firstName} />
      </animated.group>
      <animated.group
        position={springMailVal.position}
        scale={springMailVal.scale}
      >
        <TextInput label="Mail:" value={mail} />
      </animated.group>
      {firstName.length > 0 && mail.length > 0 && (
        <animated.group
          position={springBtnContinue.position}
          scale={springBtnContinue.scale}
        >
          <Button
            position={[0, -2, 0]}
            fontSize={0.3}
            onClick={() => handleSceneSwitch("welcome")}
          >
            Weiter
          </Button>
        </animated.group>
      )}
    </group>
  )
}

export default Booking
