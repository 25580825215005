import useGradientShader from "../helpers/gradientShader"

function GradientPlane({
  position = [0, 0, 0],
  dimensions = [0, 0],
  rotation = [0, 0, 0],
  onClick,
  colors = ["#17b4cc", "#944a95"],
}) {
  const gradient = useGradientShader(colors[0], colors[1])
  return (
    <mesh rotation={rotation} position={position} onClick={onClick}>
      <planeBufferGeometry attach="geometry" args={dimensions} />
      <shaderMaterial
        attach="material"
        uniforms={gradient.uniforms}
        fragmentShader={gradient.fragmentShader}
        vertexShader={gradient.vertexShader}
      />
    </mesh>
  )
}

export default GradientPlane
