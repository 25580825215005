import GradientPlane from "../components/gradientPlane"
import { Text } from "@react-three/drei"

function Button({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  onClick,
  fontSize = 0.4,
  scale = 1,
  children,
}) {
  const handleClick = () => {
    if (onClick) onClick()
  }
  const textHeight = fontSize * 1.25
  const textWidth = (fontSize * children.length) / 1.25
  return (
    <group position={position} rotation={rotation} scale={scale}>
      <GradientPlane
        position={[0, 0, -0.001]}
        dimensions={[textWidth + 0.2, textHeight + 0.2]}
        onClick={handleClick}
      />
      <Text
        color="white"
        anchorX="center"
        anchorY="middle"
        position={[0, 0, 0]}
        fontSize={fontSize}
        onClick={handleClick}
        letterSpacing={0.03}
        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
      >
        {children}
      </Text>
    </group>
  )
}

export default Button
