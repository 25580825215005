import { Text } from "@react-three/drei"
import useGradientShader from "../helpers/gradientShader"

function GradientText({
  position = [0, 0, 0],
  fontSize = 0.3,
  scale = 1,
  rotation = [0, 0, 0],
  anchorX = "center",
  anchorY = "middle",
  colors = ["#17b4cc", "#944a95"],
  font,
  children,
  onClick,
  opacity = 1,
}) {
  let gradient = useGradientShader(colors[0], colors[1])
  const handleClick = (e) => {
    if (onClick) onClick(e)
  }
  return (
    <Text
      color="white"
      anchorX={anchorX}
      anchorY={anchorY}
      position={position}
      fontSize={fontSize}
      font={font ? font : null}
      onClick={handleClick}
    >
      <shaderMaterial
        attach="material"
        uniforms={gradient.uniforms}
        fragmentShader={gradient.fragmentShader}
        vertexShader={gradient.vertexShader}
        transparent
        opacity={opacity}
      />
      {children}
    </Text>
  )
}

export default GradientText
