import { useState, useEffect, useRef } from "react"
import { useSpring, config, animated } from "@react-spring/three"
import { useFrame } from "@react-three/fiber"
import GltfModel from "../components/gltfModel"
import calculateDistance from "../helpers/calculateDistance"

function Logo({ position = [0, 0, 0], rotation = [0, 0, 0], onClick }) {
  const groupRef = useRef(null)
  const [moved, setMoved] = useState(0)

  const [lastPos, setLastPos] = useState({
    position: position
      ? { x: position[0], y: position[1], z: position[2] }
      : { x: 0, y: 0, z: 0.1 },
    rotation: rotation
      ? { x: rotation[0], y: rotation[1], z: rotation[2] }
      : { x: 0, y: 0, z: 0.1 },
  })

  const [moving, setMoving] = useState(false)

  const handleClick = (e) => {
    if (onClick) onClick(e)
  }

  const [springVal, springApi] = useSpring(() => ({
    position: [position[0], position[1], position[2]],
    rotation: [rotation[0], rotation[1], rotation[2]],
    config: { ...config.molasses, tension: 12, friction: 10, clamp: true },
  }))

  useEffect(() => {
    const dist = calculateDistance(lastPos.position, {
      x: position[0],
      y: position[1],
      z: position[2],
    })
    console.log("logo: change pos, dist:", dist, "new pos:", position)
    if (dist > 0.01) {
      springApi.start({
        position: [position[0], position[1], position[2]],
        rotation: [rotation[0], rotation[1], rotation[2]],
      })
      setLastPos({
        position: {
          x: position[0],
          y: position[1],
          z: position[2],
        },
        rotation: {
          x: rotation[0],
          y: rotation[1],
          z: rotation[2],
        },
      })
      // config: { duration: dist * 20000 },
    }
  }, [position, rotation[0], rotation[1], rotation[2]])

  useFrame(({ camera }) => {
    if (null !== groupRef.current) {
      groupRef.current.lookAt(camera.position)
    }
  })

  return (
    <animated.group ref={groupRef} position={springVal.position}>
      <GltfModel
        url="/y3.gltf"
        position={[0, -0.45, 0]}
        scale={7.5}
        onClick={handleClick}
      />
      <GltfModel
        url="/text.gltf"
        position={[0, -0.05, 1.6]}
        scale={5.2}
        onClick={handleClick}
      />
    </animated.group>
  )
}

export default Logo
