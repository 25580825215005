import VideoPlayer from "../components/videoPlayer"
import { Text } from "@react-three/drei"
import Button from "./button"

function Welcome({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  onSceneSet,
  customerName = "",
}) {
  const handleSceneSwitch = (newScene) => {
    if (onSceneSet) onSceneSet(newScene)
  }
  return (
    <group position={position} rotation={rotation}>
      <VideoPlayer
        key="videoWelcome"
        url="/Yullbe-Video-Start-Header-Mai-2021.mp4"
        position={[0, -2, -0.5]}
        scale={4}
        border={false}
        show
      />

      <Text
        color="white"
        anchorX="center"
        anchorY="middle"
        position={[-0, -0, 0]}
        opacity={0.5}
        fontSize={0.65}
      >
        Willkommen {" " + customerName}!
      </Text>

      <Button
        position={[-3, 2, 0]}
        fontSize={0.29}
        onClick={() => handleSceneSwitch("book")}
      >
        ZURÜCK
      </Button>
    </group>
  )
}

export default Welcome
