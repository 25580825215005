import { useState, useEffect, useLayoutEffect, Suspense } from "react"
import GltfModel from "./gltfModel"
import Videorahmen from "./videorahmen_eckig.js"
import { useSpring, animated } from "@react-spring/three"

function BgPlane({
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  width = 2,
  height = 1,
  border = true,
  background = true,
  scale = 1,
  startAnim = false,
  startAnimInit = false,
  hideAnim = false,
  hideAnimInit = false,
}) {
  const [enlarged, setEnlarged] = useState(startAnim ? 0 : 1)
  const [init, setInit] = useState(true)

  const { spring } = useSpring({
    spring: enlarged,
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 },
  })

  const scaleAnim = startAnim ? spring.to([0, 1], [0, scale]) : 1

  useEffect(() => {
    setInit(false)
  }, [])

  useEffect(() => {
    if (!startAnim) {
      setEnlarged(1)
    }
  }, [startAnim])
  useEffect(() => {
    if (startAnimInit) {
      setEnlarged(1)
    }
  }, [startAnimInit])

  useEffect(() => {
    if (!init && hideAnim && hideAnimInit) setEnlarged(0)
  }, [init, hideAnim, hideAnimInit])

  console.log("bgPlane: ", enlarged)

  return (
    <animated.group position={position} rotation={rotation} scale={scaleAnim}>
      <Suspense fallback={null}>
        {border && (
          <Videorahmen
            position={[width * 0.0045, height * 0.07, 0.001]}
            scale={[0.92 * width, 1.64 * height, 1]}
          />
        )}
        {background && (
          <mesh position={[0, 0, -0.001]}>
            <planeGeometry args={[width, height]} />
            <meshStandardMaterial
              attach="material"
              color="#ffffff"
              transparent
              opacity={0.05}
            />
          </mesh>
        )}
      </Suspense>
    </animated.group>
  )
}

export default BgPlane
