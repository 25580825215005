import { useState, useEffect, useLayoutEffect } from "react"
import VideoPlayer from "../components/videoPlayer"
import GradientText from "../components/gradientText"
import GradientPlane from "../components/gradientPlane"

import { Text } from "@react-three/drei"

function Start({
  active = false,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  onSceneSet,
  camArrived = false,
}) {
  const handleSceneSwitch = (newScene) => {
    if (onSceneSet) onSceneSet(newScene)
  }

  const [sceneActive, setSceneActive] = useState(false)
  const [sceneHiding, setSceneHiding] = useState(false)

  useLayoutEffect(() => {
    setSceneActive(camArrived ? true : false)
  }, [camArrived])

  useEffect(() => {
    if (sceneActive && !active) setSceneHiding(true)
  }, [active, sceneActive])

  console.log("start: camArrived:", camArrived, "sceneActive: ", sceneActive)

  return (
    <group position={position} rotation={rotation}>
      <GradientText
        position={[0, -0.13, 6.1]}
        fontSize={0.26}
        opacity={0.2}
        font="/stix_italic.ttf"
      >
        NEW EXPERIENCES
      </GradientText>

      <Text
        color="white"
        anchorX="center"
        anchorY="middle"
        position={[0, -0.48, 6.1]}
        cameraPosition={[0, -0.94, 7]}
        opacity={0.9}
        fontSize={0.26}
      >
        YULLBE
      </Text>

      <GradientText
        position={[0, -0.87, 6.1]}
        fontSize={0.26}
        font="/stix_italic.ttf"
        opacity={0.3}
      >
        IS OPEN AGAIN
      </GradientText>
      <GradientPlane
        position={[1.007, 1.103, 6.1]}
        dimensions={[0.57, 0.18]}
        onClick={() => handleSceneSwitch("book")}
      />
      <Text
        color="white"
        anchorX="center"
        anchorY="middle"
        position={[1, 1.1, 6.11]}
        cameraPosition={[1.1, 1.5, 7]}
        fontSize={0.085}
        onClick={() => handleSceneSwitch("book")}
        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
      >
        BOOK NOW
      </Text>
      <VideoPlayer
        key="videoS1"
        url="/Yullbe-Video-Start-Header-Mai-2021.mp4"
        position={[0, -1, 3.5]}
        scale={2}
        border
        show
        startAnim
        startAnimInit={sceneActive}
        hideAnim
        hideAnimInit={sceneHiding}
      />
      {/* sceneActive && <ImagePlane imageSrc="/yullbe6-scaled.jpg" /> */}
    </group>
  )
}

export default Start
