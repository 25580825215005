import { Color } from "three/src/math/Color"

const useGradientShader = (
  colorParam1 = "#17b4cc",
  colorParam2 = "#944a95"
) => {
  return {
    uniforms: {
      color1: {
        value: new Color(colorParam1),
      },
      color2: {
        value: new Color(colorParam2),
      },
    },
    vertexShader: `
      varying vec2 vUv;

      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }`,
    fragmentShader: `
      uniform vec3 color1;
      uniform vec3 color2;

      varying vec2 vUv;

      void main() {
        gl_FragColor = vec4(mix(color1, color2, vUv.x), 1.0);
      }`,
  }
}

export default useGradientShader
