import { useGLTF } from "@react-three/drei/core/useGLTF"

function GltfModel({
  url,
  position = [0, 0, 0],
  scale = 1,
  rotation = [0, 0, 0],
  opacity = 1,
  onClick,
}) {
  const gltf = useGLTF(url, true)

  const handleClick = (e) => {
    if (onClick) onClick(e)
  }
  return (
    <mesh
      position={position}
      scale={scale}
      rotation={rotation}
      opacity={opacity}
      onClick={handleClick}
    >
      <primitive object={gltf.scene} dispose={null} opacity={opacity} />
    </mesh>
  )
}

export default GltfModel
