/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF("/videorahmen_eckig.gltf")
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Curve.geometry}
        material={nodes.Curve.material}
        position={[-0.55, -0.35, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Curve001.geometry}
        material={nodes.Curve001.material}
        position={[-0.55, -0.35, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Curve002.geometry}
        material={nodes.Curve002.material}
        position={[-0.55, -0.35, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Curve003.geometry}
        material={nodes.Curve003.material}
        position={[-0.55, -0.35, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  )
}

useGLTF.preload("/videorahmen_eckig.gltf")
