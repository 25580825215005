import { useEffect, useState, useRef, useLayoutEffect } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { useSpring, config } from "@react-spring/three"
import { easeElasticInOut } from "d3-ease"
import { FlyControls, OrbitControls } from "@react-three/drei"
import calculateDistance from "../helpers/calculateDistance"
import { Vector3 } from "three/src/math/Vector3.js"

function Dolly({
  position = { x: 0, y: 0, z: 0.1 },
  lookAt = { x: 0, y: 0, z: -0.1 },
  rotation = { x: 0, y: 0, z: 0 },
  speed = 1,
  onArrival,
  rotable,
}) {
  const { camera } = useThree()
  const [lastPos, setLastPos] = useState({
    position: position
      ? { x: position.x, y: position.y, z: position.z }
      : { x: 0, y: 0, z: 0.1 },
    lookAt: lookAt
      ? { x: lookAt.x, y: lookAt.y, z: lookAt.z }
      : { x: 0, y: 0, z: -0.2 },
    rotation: { x: 0, y: 0, z: 0 },
  })
  const lastRotation = useRef({ x: 0, y: 0, z: 0 })
  const [speedVal, setSpeedVal] = useState(1)
  const [moving, setMoving] = useState(false)
  const [controlsMode, setControlsMode] = useState("orbital")
  const [lastDolliedCameraOrientation, setLastDolliedCameraOrientation] =
    useState({ x: -1, y: -1, z: -1 })
  const [init, setInit] = useState(true)
  const controlsRef = useRef()
  const cameraDummyRef = useRef(null)
  const targetRotationRef = useRef({ x: 0, y: 0, z: 0 })
  const infoRef = useRef(document.getElementById("info"))

  const setLastDolliedCameraOrientationState = () => {
    setLastDolliedCameraOrientation({
      x: camera.rotation.x,
      y: camera.rotation.y,
      z: camera.rotation.z,
    })
    setInit(false)
    console.log("t", typeof controlsRef.current)
    if (controlsRef.current && null !== controlsRef.current) {
      controlsRef.current.update()
      console.log(controlsRef.current)
    }
    console.log("dolly: onRest rotation:", camera.rotation)
  }
  const handleCameraChange = () => {
    if (
      camera.position.x !== lastPos.x ||
      camera.position.y !== lastPos.y ||
      camera.position.z !== lastPos.z
    ) {
      console.log("dolly: camera changed by user")
      /*
      setLastPos({
        x: camera.rotation.x,
        y: camera.rotation.y,
        z: camera.rotation.z,
      })
      */
    }
  }

  const handleArrive = () => {
    if (controlsRef && controlsRef.current) {
      camera.position.set(position.x, position.y, position.z)
      controlsRef.current.target.set(lookAt.x, lookAt.y, lookAt.z)
      // controlsRef.current.update()
    }
    setMoving(false)
    setLastPos({ position, lookAt, rotation })
    console.log("dolly: arrived")
    setLastDolliedCameraOrientationState()
    if (onArrival)
      onArrival({ position: lastPos.position, lookAt: lastPos.lookAt })
  }

  const [springPosVal, springPos] = useSpring(() => ({
    posX: 0,
    posY: 0,
    posZ: 0,
    lookAtX: 0,
    lookAtY: 0,
    lookAtZ: 0,
    rotationX: 0,
    rotationY: 0,
    rotationZ: 0,
    config: {
      ...config.molasses,
      tension: 50,
      friction: 130,
      clamp: true,
    },
    onRest: () => handleArrive(),
  }))

  const changePosition = (targetPosition, targetLookAt) => {
    if (cameraDummyRef.current) {
      cameraDummyRef.current.position.x = targetPosition.x
      cameraDummyRef.current.position.y = targetPosition.y
      cameraDummyRef.current.position.z = targetPosition.z
      cameraDummyRef.current.lookAt(lookAt.x, lookAt.y, lookAt.z)
      targetRotationRef.current = {
        x: -cameraDummyRef.current.rotation.x,
        y: -cameraDummyRef.current.rotation.y,
        z: -cameraDummyRef.current.rotation.z,
      }
      // setTargetRotation(targetRotationRef.current)
    }

    const dist = calculateDistance(
      init ? lastPos.position : camera.position,
      targetPosition
    )

    let changeVal = Math.max(
      dist,
      Math.max(
        Math.abs(lastRotation.current.x + rotation.x),
        Math.abs(lastRotation.current.y + rotation.y),
        Math.abs(lastRotation.current.z + rotation.z)
      ) * 1.5
    )

    console.log(
      "dolly: positionChange: target:",
      targetPosition,
      "lookAt:",
      targetLookAt,
      "rotation:",
      rotation,
      " distance:",
      dist,
      "changeVal:",
      changeVal
    )
    if (changeVal > 0.01 || init) {
      setMoving(true)
      // TODO: Change lookAt to rotation, calculating rotation by helper vector or object3d that looksAt target from target camera position
      springPos.start({
        from: {
          posX: camera.position.x,
          posY: camera.position.y,
          posZ: camera.position.z,
          lookAtX: lastPos.lookAt.x,
          lookAtY: lastPos.lookAt.y,
          lookAtZ: lastPos.lookAt.z,
          rotationX: lastRotation.current.x,
          rotationY: lastRotation.current.y,
          rotationZ: lastRotation.current.z,
        },
        to: {
          posX: targetPosition.x,
          posY: targetPosition.y,
          posZ: targetPosition.z,
          lookAtX: targetLookAt.x,
          lookAtY: targetLookAt.y,
          lookAtZ: targetLookAt.z,
          rotationX: rotation.x,
          rotationY: rotation.y,
          rotationZ: rotation.z,
        },
        config: { duration: (dist * 2900) / changeVal },
      })
      lastRotation.current = rotation
      setLastPos({ position, lookAt, rotation: lastRotation.current })
    }
  }

  const handleKeypresses = (event) => {
    console.log(event)
    if (event.key === "#") {
      changePosition(position)
    }
    if (event.key === "<") {
      console.log("control mode changed")
      setControlsMode(controlsMode === "fly" ? "orbital" : "fly")
    }
  }

  useEffect(() => {
    document.addEventListener("keypress", handleKeypresses)
    camera.position.x = 0
    camera.position.y = 0
    camera.position.z = 0
    window.cameraState = {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
    }
    return () => document.removeEventListener("keypress", handleKeypresses)
  }, [])

  useEffect(() => {
    setSpeedVal(speed)
  }, [speed])

  useLayoutEffect(() => {
    changePosition(position, lookAt)
    if (init) setInit(false)
  }, [
    position.x,
    position.y,
    position.z,
    lookAt.x,
    lookAt.y,
    lookAt.z,
    rotation.x,
    rotation.y,
    rotation.z,
  ])

  console.log("orbit", controlsRef.current)

  /*
  useEffect(() => {
      console.log('dolly: lookAtChange')
      setPanning(true)
      if (lastDolliedCameraOrientation.x !== camera.rotation.x || lastDolliedCameraOrientation.y !== camera.rotation.y || lastDolliedCameraOrientation.z !== camera.rotation.z)
        springLookAt.start({ x: lookAt.x, y: lookAt.y, z: lookAt.z })
      setLastLookAt({ x: lookAt.x, y: lookAt.y, z: lookAt.z })
  }, [lookAt.x, lookAt.y, lookAt.z])
  */

  console.log(
    "dolly: positionParam:",
    position,
    "springValPos:",
    [springPosVal.posX.get(), springPosVal.posY.get(), springPosVal.posZ.get()],
    "lastPos:",
    lastPos.position,
    "camera: pos:",
    camera.position,
    "rotationParam:",
    rotation,
    "camRotation:",
    camera.rotation,
    "lastPosRot:",
    lastPos.rotation,
    "lastRotation:",
    lastRotation.current
  )

  useFrame(({ camera }) => {
    /*
    if (moving) {
      camera.position.x = springPosVal.x.get()
      camera.position.y = springPosVal.y.get()
      camera.position.z = springPosVal.z.get()
    }
    if (panning || moving) {
      camera.lookAt(
        springLookAtVal.x.get(),
        springLookAtVal.y.get(),
        springLookAtVal.z.get()
      )
    }
    */
    /*
    if (position.x !== lastPos.position.x || position.y !== lastPos.position.y || position.z !== lastPos.position.z || lookAt.x !== lastPos.lookAt.x || lookAt.y !== lastPos.lookAt.y || lookAt.z !== lastPos.lookAt.z) {
      cameraDummyRef.current.position.x =    }

    */
    if (moving) {
      camera.position.x = springPosVal.posX.get()
      camera.position.y = springPosVal.posY.get()
      camera.position.z = springPosVal.posZ.get()
      /*
      camera.lookAt(
        springPosVal.lookAtX.get(),
        springPosVal.lookAtY.get(),
        springPosVal.lookAtZ.get()
      )
      */
      camera.rotation.x = springPosVal.rotationX.get()
      camera.rotation.y = springPosVal.rotationY.get()
      camera.rotation.z = springPosVal.rotationZ.get()
    }

    infoRef.current.innerHTML =
      "Position: <br>x: " +
      Math.round(camera.position.x * 10) / 10 +
      "<br>y: " +
      Math.round(camera.position.y * 10) / 10 +
      "<br>z: " +
      Math.round(camera.position.z * 10) / 10 +
      "<br><br>" +
      "Rotation:<br>x:" +
      Math.round(camera.rotation.x * 10) / 10 +
      "<br>y:" +
      Math.round(camera.rotation.y * 10) / 10 +
      "<br>z: " +
      Math.round(camera.rotation.z * 10) / 10

    /*
    if (rotating) {
      camera.rotation.x = springRotationResetVal.x.get()
      camera.rotation.y = springRotationResetVal.y.get()
      camera.rotation.z = springRotationResetVal.z.get()
    }
    */

    if (controlsRef.current && null !== controlsRef.current) {
      // controlsRef.current.update()
      /*
      controlsRef.current.target.x = springPosVal.lookAtX.get()
      controlsRef.current.target.y = springPosVal.lookAtY.get()
      controlsRef.current.target.z = springPosVal.lookAtZ.get()
      */
    }
  })
  /*

  useEffect(() => {
    if (!moving && !panning) {
      if (null !== controlsRef.current)
        controlsRef.current.addEventListener("change", handleCameraChange)
    }
  }, [])

  return !moving && !panning ? (
    controlsMode === "fly" ? (
      <FlyControls ref={controlsRef} dragToLook />
    ) : (
      <OrbitControls
        ref={controlsRef}
        enabled={!moving && !panning}
        target={
          new Vector3(
            springLookAtVal.x.get(),
            springLookAtVal.y.get(),
            springLookAtVal.z.get()
          )
        }
      />
    )
  ) : null
  */

  // return null
  return (
    <>
      {!moving && rotable && (
        <OrbitControls enabled={!moving && rotable} ref={controlsRef} />
      )}
      {/*<FlyControls ref={controlsF} /> */}
      <object3D
        ref={cameraDummyRef}
        position={[position.x, position.y, position.z]}
      />
    </>
  )
  /*
  return controlsMode === "fly" ? (
    <FlyControls ref={controlsRef} dragToLook />
  ) : (
    <OrbitControls
      ref={controlsRef}
      target={
        new Vector3(
          springLookAtVal.x.get(),
          springLookAtVal.y.get(),
          springLookAtVal.z.get()
        )
      }
    />
  )
  */

  // return <OrbitControls ref={controlsRef} position0={new Vector3(position.x, position.y, position.z)}  target={new Vector3(springLookAtVal.x.get(), springLookAtVal.y.get(), springLookAtVal.z.get())} />
}

export default Dolly
