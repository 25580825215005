interface Coordinate {
  x: number
  y: number
  z: number
}

function calculateDistance(p1: Coordinate, p2: Coordinate): number {
  var a = p2.x - p1.x
  var b = p2.y - p1.y
  var c = p2.z - p1.z

  return Math.sqrt(a * a + b * b + c * c)
}

export default calculateDistance
