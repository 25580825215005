import { useEffect, useState, useRef, Suspense } from "react"
import "./styles.css"
import { Canvas } from "@react-three/fiber"
import { Stars, useDetectGPU } from "@react-three/drei"
import Dolly from "./components/dolly"

import Logo from "./elements/logo"
import Start from "./elements/start"
import Booking from "./elements/booking"
import Welcome from "./elements/welcome"

function App() {
  const GPUTier = useDetectGPU()
  // const [cam, setCam] = useState({ position: { x: 0, y: 0, z: 0.01 }, lookAt: { x: 0, y: 0, z: 0 }})
  const [camLookAt, setCamLookAt] = useState({ x: 0, y: 0, z: 0 })
  const [camRotation, setCamRotation] = useState({ x: 0, y: 0, z: 0 })
  const [camPos, setCamPos] = useState({ x: 0, y: 0, z: 0 })
  const [camSpeed, setCamSpeed] = useState(2)
  const [camReset, setCamReset] = useState(false)
  const [camArrived, setCamArrived] = useState(false)
  const [camRotable, setCamRotable] = useState(true)
  const [scene, setScene] = useState("init")
  const [showStart, setShowStart] = useState(true)
  const [showBooking, setShowBooking] = useState(false)
  const [showWelcome, setShowWelcome] = useState(false)
  const [customer, setCustomer] = useState({
    firstName: "",
    mail: "",
  })
  const [logoPos, setLogoPos] = useState({
    position: [0, 0, 0],
    rotation: [0, 0, 0],
  })

  const camArrivalFunction = useRef(null)

  const handleSwitchScene = (newScene) => {
    if (newScene !== scene) {
      setCamArrived(false)
      setScene(newScene)
    }
  }

  const handleCamArrived = () => {
    setCamArrived(true)
  }

  const handleCustomerChange = (newCustomerData) => {
    setCustomer({
      firstName: newCustomerData.firstName,
      mail: newCustomerData.mail,
    })
  }

  useEffect(() => {
    if (GPUTier) console.log("gpu: ", GPUTier)
  }, [GPUTier])

  useEffect(() => {
    setCamPos({ x: 0, y: 0, z: 0.1 })
    setTimeout(() => {
      // setCam({ position: {x: 0, y: 0, z: 5}, lookAt: { x: 0, y: 0, z: 3 }, ...cam }), 1000)
      setCamPos({ x: 0, y: 0, z: 8.5 })
      // setCamLookAt({ x: 0, y: -1, z: 0 })
      camArrivalFunction.current = () => {
        console.log("angekommen")
      }
    }, 500)
    setTimeout(() => {
      setScene("start")
    }, 3000)

    setTimeout(() => {
      setCamArrived(true)
    }, 4500)

    /*
    setTimeout(() => {
      setCamPos({ x: -1, y: -1, z: 8 })
      setCamLookAt({ x: 2, y: 0, z: 4 })
      setCamSpeed(3)
    }, 7000)
    setTimeout(() => {
      setCamPos({ x: -1, y: -1, z: 8 })
      setCamLookAt({ x: 2, y: 3, z: 6 })
      setCamSpeed(3)
    }, 10000)
    */
  }, [])

  useEffect(() => {
    switch (scene) {
      case "init":
        setCamPos({ x: 0, y: 0, z: 0.1 })
        setCamRotation({ x: 0, y: 0, z: 0 })
        setCamLookAt({ x: 0, y: 0, z: 0 })
        setCamRotable(true)
        setLogoPos({ position: [0, 0, 0], rotation: [0, 0, 0] })
        if (!showStart) setShowStart(true)
        break
      case "start":
        setCamPos({ x: 0, y: 0, z: 8.5 })
        setCamRotation({ x: 0, y: 0, z: 0 })
        setCamLookAt({ x: 0, y: 0, z: 0 })
        setLogoPos({ position: [0, 5, 0], rotation: [0, 0, 0] })
        setCamRotable(true)
        setShowStart(true)
        if (showBooking) setTimeout(() => setShowBooking(false), 5000)
        if (showWelcome) setTimeout(() => setShowWelcome(false), 5000)
        break
      case "book":
        setCamPos({ x: 0, y: 5, z: -1 })
        setCamRotation({ x: -Math.PI / 2, y: 0, z: 0 })
        setCamLookAt({ x: 0, y: -1, z: -1 })
        setCamRotable(false)
        setLogoPos({ position: [0, -2, -3.9], rotation: [-Math.PI / 2, 0, 0] })
        setShowBooking(true)
        if (showStart) setTimeout(() => setShowStart(false), 1000)
        if (showWelcome) setTimeout(() => setShowWelcome(false), 5000)
        break
      case "welcome":
        setCamPos({ x: 10, y: -3, z: -4 })
        setCamRotation({ x: 0, y: -Math.PI / 2, z: 0 })
        setCamLookAt({ x: 15, y: -3, z: -4.0 })
        setCamRotable(false)
        setLogoPos({ position: [17, 1, -4] })
        setShowWelcome(true)
        if (showStart) setTimeout(() => setShowStart(false), 5000)
        if (showBooking) setTimeout(() => setShowBooking(false), 5000)
        break
      default:
        setCamPos({ x: 0, y: 0, z: -3 })
        setCamRotation({ x: 0, y: 0, z: 0 })
        setCamLookAt({ x: 0, y: 0, z: 0 })
        setLogoPos({ position: [0, 0, 0], rotation: [0, 0, 0] })
        if (showWelcome) setTimeout(() => setShowWelcome(false), 5000)
        if (showBooking) setTimeout(() => setShowBooking(false), 5000)
    }
  }, [scene])

  console.log("App: camArrived:", camArrived, "scene:", scene)

  return (
    <Canvas>
      {" "}
      {/* invalidateFrameloop> */}
      {/* <Camera position={camPos}  /> */}
      <Suspense fallback={null}>
        {/* <OrbitControls /> */}
        <Stars />
        <Dolly
          update={camReset}
          position={camPos ? camPos : null}
          rotation={camRotation ? camRotation : null}
          lookAt={camLookAt}
          speed={camSpeed}
          onArrival={handleCamArrived}
          rotable={camRotable}
        />
        <ambientLight intensity={0.5} />
        <spotLight position={[15, 90, 90]} angle={0.3} />

        {/* <Box /> */}
        <Logo
          position={logoPos.position}
          rotation={logoPos.rotation}
          onClick={() => handleSwitchScene("start")}
        />

        {/* }<Model
          url="/eurolink.gltf"
          position={[0, -1, 3]}
          scale={0.1}
          rotation={[-0.0, 0.3, 0]}
        /> */}

        {showStart && (
          <Start
            onSceneSet={handleSwitchScene}
            camArrived={camArrived}
            active={scene === "start" || scene === "init"}
          />
        )}

        {showBooking && (
          <Booking
            active={scene === "book"}
            camArrived={camArrived}
            customer={{ firstName: customer.firstName, mail: customer.mail }}
            position={[0, -1, -1]}
            rotation={[-Math.PI / 2, 0, 0]}
            onSceneSet={handleSwitchScene}
            onBooking={handleCustomerChange}
            scale={1}
          />
        )}

        {showWelcome && (
          <Welcome
            active={scene === "book"}
            camArrived={camArrived}
            position={[15, -3, -4]}
            rotation={[0, -Math.PI / 2, 0]}
            customerName={customer.firstName}
            onSceneSet={handleSwitchScene}
            scale={1}
          />
        )}
      </Suspense>
    </Canvas>
  )
}

export default App
